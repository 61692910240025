import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import { Container, Row, Column, Stack } from '../../components/Layout'
import Header from '../../components/Header'
import SectionCard from '../../components/SectionCard'
import CaseStudyDetails from '../../components/CaseStudyDetails'
import Heading from '../../components/Heading'
import Text from '../../components/Text'

export const frontmatter = {
  title: 'Mobile Order',
  description:
    'Mobile ordering coffee should be easy, intutive, and save you time.',
  thumbnail: '../../images/work/mobile-order/thumbnail.png',
  order: 2,
}

const MobileOrder = ({ path, pageContext, data }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.siteInfo.siteMetadata.title}</title>
      <link rel="canonical" href={data.siteInfo.siteMetadata.url} />
      <meta
        name="description"
        content={data.siteInfo.siteMetadata.description}
      />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      />
    </Helmet>
    <Header title={frontmatter.title} intro={frontmatter.description} secondary>
      <Img
        fluid={data.asset00.childImageSharp.fluid}
        alt="mobile order thumbnail"
      />
    </Header>
    <Row verticalSpacing={[2, 0]}>
      <CaseStudyDetails
        items={[
          { key: 'Project Length (Conceptual)', value: '4 hours' },
          { key: 'Primary Design Tool', value: 'Figma' },
          { key: 'My Role', value: 'Designer/Creator' },
        ]}
      />
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Stack space={1}>
          <Text>Problem</Text>
          <Text>
            For users who like to mobile order their coffee every morning, time
            is of the essence. There were several pain points when using a local
            coffee shop’s mobile order app that made the experience more tedious
            and less enjoyable.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Stack space={1}>
          <Text>Solution</Text>
          <Text>
            A simple redesign of the app to enhance user-friendliness, good
            design practices, and get through the order process more
            efficiently.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2">
          Audit
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Text>
          
          To get started, I conducted an audit of the existing UI to get a
          better understanding of the overall function and flow of the app.
        </Text>
      </Column>
    </Row>
    <div className="bg-beige mt-8 p-16">
      <Row verticalSpacing={[4, 4]}>
        <Column span={12}>
          <Img
            fluid={data.asset01.childImageSharp.fluid}
            alt="stone creek audit 1"
          />
        </Column>
      </Row>
    </div>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Text>
          My initial observations focused on areas of the design that would need
          to be changed. The impression this app leaves users with is feelings
          of clumsiness, unattractiveness, and an overall feeling of clutter.
        </Text>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Text>
          Specific elements make the app less enjoyable to use and visually
          unappealing including:
        </Text>
      </Column>
    </Row>
    <Row verticalSpacing={[1, 0]}>
      <Column span={6}>
        <Stack space={1}>
          <ul className="list-disc list-outside">
            <li>Close margins to the edge of the screen</li>
            <li>Unclear primary and secondary actions</li>
            <li>
              Lists and other data tightly packed together making it difficult
              to quickly scan
            </li>
            <li>Color choice - cool grays mixed with warm orange tones</li>
            <li>
              Navigation has more detail than necessary and can be confusing
            </li>
          </ul>
        </Stack>
      </Column>
    </Row>
    <div className="bg-beige mt-8 p-16">
      <Row verticalSpacing={[4, 4]}>
        <Column span={12}>
          <Img
            fluid={data.asset02.childImageSharp.fluid}
            alt="stone creek audit 2"
          />
        </Column>
      </Row>
    </div>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Stack space={2}>
          <Text>
            Another pain point for the user is the flow of actually ordering the
            drink. Since there are so many options to choose from in how one can
            customize their drink, it would be important to “chunk” the process
            to ease the process for users to scan and select their drink
            options.
          </Text>
          <Text>
            As it currently stands, Stone Creek’s app has every drink
            modification in one long list that users scroll through which is not
            as efficient for users who are looking for a specific modification
            or don’t want any modifications to their drink.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={4}>
        <Img
          fluid={data.asset03.childImageSharp.fluid}
          alt="simplification, stone creek screen"
        ></Img>
      </Column>
      <Column offset={7} span={6}>
        <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
          Simplification
        </Heading>
        <Stack space={2}>
          <Text>
            Giving the user a singular option within a flow elimnates the need
            to parse through multiple actions and suspend decision-making until
            it’s necessary.
          </Text>
          <Text>
            I also chose to remove the bottom navigation since the user would
            easily be able to edit their location later on in the flow.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
          Visual
        </Heading>
        <Stack space={2}>
          <Text>
            Wanting to make the entire experience more visually appealing, I
            decided to include thumbnail images for each product users could
            pruchase and round the corners to give a friendly feeling.
          </Text>
          <Text>
            Another goal was to reduce the cluttered feeling the previous
            experience had so I gave some breathing room between each list item.
            The new flow also directs users to drill into a category rather than
            selecting from a large list with everything laid out in the open.
          </Text>
          <Text>
            Users are able to toggle back and forth between the menu and their
            past orders to shorten the amount of time spent searching for a
            specific item.
          </Text>
        </Stack>
      </Column>
      <Column offset={8} span={5}>
        <Img
          fluid={data.asset04.childImageSharp.fluid}
          alt="visual, menu and past orders"
        ></Img>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 10]}>
      <Column span={5}>
        <Img
          fluid={data.asset04.childImageSharp.fluid}
          alt="chunking, yarn stalatte size and modifications menu"
        ></Img>
      </Column>
      <Column offset={7} span={6}>
        <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
          Chunking
        </Heading>
        <Stack space={2}>
          <Text>
            The psychological principle of chunking – where the brain divides
            large amounts of data into smaller units in order to retain them
            more easily for short term memory – was the basis for my decision to
            split the drink modification flow into multiple screens.
          </Text>
          <Text>
            One pain point that I identified with the former design was the
            amount of information that was displayed at a time. The previous UI
            had a long list of modifiction and add-ons which I found to be
            overwhelming and difficult to quickly scan.
          </Text>
          <Text>
            To solve this problem, I divided the information into two separate
            screens so users could make one decision at a time: size, then
            add-ons. I also found the amount of choices for add-ons to be
            overwhelming and decided to reduce the number of choices so users
            could quickly make decisions and get on with their day.
          </Text>
        </Stack>
      </Column>
    </Row>
    <div className="bg-beige mt-8 p-16">
      <Row verticalSpacing={[4, 4]}>
        <Column span={6}>
          <Heading level={1.5} as="h2" style={{ marginBottom: '2rem' }}>
            Hierarchy
          </Heading>
          <Stack space={2}>
            <Text>
              Having a clear hierarchy keeps designs organized and contributes
              to an overall seamless flow. Knowing what task a user is trying to
              accomplish on any given screen When the user reaches this screen
              they are trying to accomplish a specific task: reviewing their
              order and beginning the checkout flow.
            </Text>
            <Text>
              One of the shortcomings of the original design is the lack of
              clear hierarchy and several different type sizes. It gives users a
              feeling of clumsiness.
            </Text>
            <Text>
              My updated version put an emphasis on clear hierarchy by having a
              large bold title at the top so users know they are in their cart.
              I then listed the item in their cart all using one type size and
              instead used color to differentiate between the list item name,
              and modifications the user made on a previous screen.
            </Text>
          </Stack>
        </Column>
        <Column offset={7} span={6}>
          <Img
            fluid={data.asset06.childImageSharp.fluid}
            alt="hierarchy, comparing original and new design for customer's cart"
          ></Img>
        </Column>
      </Row>
    </div>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2">
          Color Palette
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={12}>
        <Img
          fluid={data.asset07.childImageSharp.fluid}
          alt="color palette comparing contrast ratio scores"
        ></Img>
      </Column>
    </Row>
    <Row verticalSpacing={[2, 0]}>
      <Column span={6}>
        <Stack space={2}>
          <Text>
            When choosing the new brand color, accessibility was a key factor in
            that decision. The original orange did not reach AA contrast
            standard. Choosi ng an orange that surpassed a 4.5 contrast ratio
            score was difficult, but the hue I ended up with serves a larger
            community and would still be recognizable to long-time users as the
            “Stone Creek Coffee Orange.”
          </Text>
          <Text>
            I decided to play into the warmth of the primary interactive
            elements and use a slightly orange-y hue for the primary text as
            well to help each and every screen feel cohesive.
          </Text>
        </Stack>
      </Column>
    </Row>
    <Row verticalSpacing={[12.5, 0]}>
      <Column span={6}>
        <Heading level={1.5} as="h2">
          Strengths & Challenges
        </Heading>
      </Column>
    </Row>
    <Row verticalSpacing={[4, 6]}>
      <Column span={5}>
        <Heading level={2} as="h2">
          Strengths
        </Heading>
        <ul className="list-disc list-outside">
          <li>
            New design improves overall flow and reduces amount of clutter per
            screen
          </li>
          <li>Accessible color palette, most notably the brand orange</li>
          <li>
            Increased user confidence with clear hierarchy and organization
            throughout
          </li>
        </ul>
      </Column>
      <Column span={5}>
        <Heading level={2} as="h2">
          Challenges
        </Heading>
        <ul className="list-disc list-outside">
          <li>
            Used same type as in original app whereas I could have invested time
            into choosing a different font
          </li>
          <li>Secondary text color is just shy of passing AA color ratio</li>
          <li>
            Less choices for the user in terms of drink modifications than in
            the original design.
          </li>
        </ul>
      </Column>
    </Row>
  </>
)

export const query = graphql`
  query MobileOrderQuery {
    siteInfo: site {
      siteMetadata {
        name
        title
        description
        url
      }
    }
    asset00: file(relativePath: { eq: "work/mobile-order/asset-00.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset01: file(relativePath: { eq: "work/mobile-order/asset-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset02: file(relativePath: { eq: "work/mobile-order/asset-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset03: file(relativePath: { eq: "work/mobile-order/asset-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset04: file(relativePath: { eq: "work/mobile-order/asset-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset05: file(relativePath: { eq: "work/mobile-order/asset-05.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset06: file(relativePath: { eq: "work/mobile-order/asset-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    asset07: file(relativePath: { eq: "work/mobile-order/asset-07.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    thumbnail: file(relativePath: { eq: "work/mobile-order/thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`

export default MobileOrder
